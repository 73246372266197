import React from 'react';
import background from "./background.jpg";
import ReactDOM from 'react-dom/client';
import { Toolbar, AppBar, IconButton, Box, Typography, CssBaseline } from "@mui/material";
import * as Icons from '@mui/icons-material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './index.css';

import reportWebVitals from './reportWebVitals';
import GooglePlayBadge from './google-play-badge.png';  // Google Play Badge hinzufügen

const theme = createTheme({
  palette: {
    primary: {
      main: "#ffffff",
    },
  },
  typography: {
    fontFamily: 'Holtzberg, Arial, sans-serif',
    h4: {
      fontSize: '2.5rem', // Desktop-Version Schriftgröße
      '@media (max-width:600px)': { // Mobile Version
        fontSize: '1.5rem',
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          height: '100vh',
          width: "100vw",
          backgroundImage: `url(${require('./background.jpg')})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >

        {/* Desktop-Version */}
        <Box sx={{ display: { xs: 'none', md: 'block' } }}> {/* Wird auf mobilen Geräten ausgeblendet */}
          <AppBar id="appbar" position="static" sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
            <Toolbar sx={{ justifyContent: 'center' }}>
              <IconButton
                size="large"
                edge="start"
                aria-label="Instagram"
                sx={{ mr: 2 }}
                href='https://instagram.com/kaufbeimbauer'>
                <Icons.Instagram fontSize="large" />
              </IconButton>
              <IconButton
                size="large"
                edge="start"
                aria-label="Facebook"
                sx={{ mr: 2 }}
                href='https://facebook.com/61555566786764'>
                <Icons.Facebook fontSize="large" />
              </IconButton>
              <IconButton
                size="large"
                edge="start"
                aria-label="YouTube"
                sx={{ mr: 2 }}
                href='https://youtube.com/@kaufbeimbauer'>
                <Icons.YouTube fontSize="large" />
              </IconButton>
            </Toolbar>
          </AppBar>

          <Typography variant="h4" color="primary" sx={{ textAlign: 'center', mt: 3 }}>
            KaufbeimBauer.com
          </Typography>

          <Box sx={{ mt: 7, justifyContent: 'center', display: 'flex' }}>
            <a href="https://play.google.com/store/apps/details?id=de.kbb.kaufbeimbauern&pcampaignid=web_share" target="_blank" rel="noopener noreferrer">
              <img src={GooglePlayBadge} alt="Download from Google Play" style={{ width: '200px' }} />
            </a>
          </Box>
        </Box>

        {/* Mobile-Version */}
        <Box sx={{ display: { xs: 'block', md: 'none' } }}> {/* Wird auf Desktop-Geräten ausgeblendet */}
          <AppBar id="appbar" position="static" sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
            <Toolbar sx={{ justifyContent: 'center' }}>
              <IconButton
                size="large"
                edge="start"
                aria-label="Instagram"
                sx={{ mr: 1 }}
                href='https://instagram.com/kaufbeimbauer'>
                <Icons.Instagram fontSize="medium" />
              </IconButton>
              <IconButton
                size="large"
                edge="start"
                aria-label="Facebook"
                sx={{ mr: 1 }}
                href='https://facebook.com/61555566786764'>
                <Icons.Facebook fontSize="medium" />
              </IconButton>
              <IconButton
                size="large"
                edge="start"
                aria-label="YouTube"
                sx={{ mr: 1 }}
                href='https://youtube.com/@kaufbeimbauer'>
                <Icons.YouTube fontSize="medium" />
              </IconButton>
            </Toolbar>
          </AppBar>

          <Typography variant="h4" color="primary" sx={{ textAlign: 'center', mt: 2 }}>
            KaufbeimBauer.com
          </Typography>

          <Box sx={{ mt: 6, justifyContent: 'center', display: 'flex' }}>
            <a href="https://play.google.com/store/apps/details?id=de.kbb.kaufbeimbauern&pcampaignid=web_share" target="_blank" rel="noopener noreferrer">
              <img src={GooglePlayBadge} alt="Download from Google Play" style={{ width: '150px' }} />
            </a>
          </Box>
        </Box>

      </Box>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
